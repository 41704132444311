<template>
  <div class="section">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="所属社区"
        :rules="[{ required: false, message: '请选择社区', trigger: 'blur' }]"
        prop="communityId"
      >
        <v-cascader
          :disabled="isView"
          placeholder="默认表示全部"
          :width="width"
          v-model="authSpaceIds"
          :options="communityOps"
          :props="props"
        />
      </el-form-item>

      <el-form-item
        label="发布板块"
        :rules="[
          { required: true, message: '请选择发布板块', trigger: 'change' },
        ]"
        prop="goodsType"
      >
        <v-select
          :disabled="isView"
          :width="width"
          :options="goodsTypeOps"
          v-model="form.goodsType"
        />
      </el-form-item>

      <el-form-item
        label="微信支付商家"
        :rules="[
          { required: true, message: '请选择微信支付商家', trigger: 'change' },
        ]"
        prop="subMchInfoId"
      >
        <v-select
          :disabled="isView"
          :width="width"
          :options="subMchInfoIdOps"
          v-model="form.subMchInfoId"
        />
      </el-form-item>

      <el-form-item
        label="封面"
        :rules="[
          {
            required: true,
            message: '请上传封面',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="imageUrl"
      >
        <v-upload :disabled="isView" :limit="1" :imgUrls.sync="form.imageUrl" />
      </el-form-item>

      <el-form-item
        label="标题"
        :rules="[{ required: true, message: '请输入标题', trigger: 'blur' }]"
        prop="title"
      >
        <v-input
          :disabled="isView"
          :width="width"
          placeholder="请输入标题"
          v-model="form.title"
        ></v-input>
      </el-form-item>
      <template v-if="curGoodsType.category == 3">
        <el-form-item
          label="外链"
          :rules="[{ required: true, message: '请输入外链', trigger: 'blur' }]"
          prop="otherLink"
        >
          <v-input
            :disabled="isView"
            :width="width"
            placeholder="请输入标题"
            v-model="form.otherLink"
          ></v-input>
        </el-form-item>
      </template>
      <template v-if="curGoodsType.category == 1">
        <el-form-item
          label="提货地点"
          :rules="[
            { required: true, message: '请输入提货地点', trigger: 'blur' },
          ]"
          prop="getAddress"
        >
          <div class="address">
            <div
              class="address-input"
              v-for="(item, index) in form.getAddress"
              :key="index"
            >
              <v-input
                :disabled="isView"
                :width="width"
                placeholder="请输入"
                v-model="form.getAddress[index]"
              ></v-input>
              <template v-if="!isView">
                <v-button
                  v-if="index == 0"
                  text="新增"
                  @click="setAddress(item, index, form.getAddress)"
                />
                <v-button
                  v-else
                  type="warning"
                  text="删除"
                  @click="setAddress(item, index, form.getAddress)"
                />
              </template>
            </div>
          </div>
        </el-form-item>
        <!-- <el-form-item
          label="价格"
          :rules="[
            { required: true, message: '请输入价格', trigger: 'blur' },
            {
              message: '必须是数字格式，且精度为小数点后两位',
              pattern: /^[0-9]\d*(.\d{1,2})?$/,
              trigger: 'blur',
            },
          ]"
          prop="price"
        >
          <v-input
            :disabled="isView"
            :width="width"
            placeholder="请输入价格"
            v-model="form.price"
          ></v-input>
        </el-form-item> -->
        <!-- <el-form-item
          label="库存"
          :rules="[
            { required: false, message: '请输入库存', trigger: 'blur' },
            {
              message: '必须是数字格式',
              pattern: /^[0-9]+$/,
              trigger: 'blur',
            },
          ]"
          prop="stock"
        >
          <v-input
            :disabled="isView"
            :width="width"
            placeholder="默认为无限量"
            v-model="form.stock"
          ></v-input>
        </el-form-item> -->
        <el-form-item label="商品规格">
          <div class="sku">
            <v-button
              size="medium"
              type="success"
              icon="el-icon-plus"
              text="添加规格"
              @click="addSku"
            >
            </v-button>
            <div class="sku-table">
              <v-table
                :tableData="form.skuList"
                ref="vTable"
                :headers="headers"
                :operateColumnWidth="120"
              >
                <template #operateTeSlot="scope">
                  <div class="opera-btn">
                    <v-button
                      type="text"
                      @click="editSku(scope.row.$index)"
                      text="编辑"
                    ></v-button>
                    <v-button
                      type="text"
                      @click="delSku(scope.row.$index)"
                      text="删除"
                    ></v-button>
                  </div>
                </template>
              </v-table>
            </div>
          </div>
        </el-form-item>
      </template>
      <template v-if="curGoodsType.category == 1 || curGoodsType.category == 2">
        <el-form-item
          label="内容"
          :rules="[{ required: false, message: '请输入内容', trigger: 'blur' }]"
          prop="detailContent"
        >
          <v-ueditor :isDisable="isView" v-model="form.detailContent" />
        </el-form-item>
      </template>
      <el-form-item
        label="发布状态"
        :rules="[{ required: true, message: '请选择状态', trigger: 'blur' }]"
        prop="shelfSts"
      >
        <v-select
          :disabled="isView"
          :width="width"
          :options="shelfStsOps"
          v-model="form.shelfSts"
        />
      </el-form-item>

      <!-- <el-form-item
        label="审核状态"
        :rules="[{ required: true, message: '请选择状态', trigger: 'blur' }]"
        prop="shelfSts"
      >
        <v-select
          :width="width"
          :options="shelfStsOps"
          v-model="form.shelfSts"
        />
      </el-form-item> -->
    </form-panel>

    <v-dialog
      title="商品规格信息"
      v-model="isDialog"
      width="500px"
      @close="close"
      @confirm="saveSku"
    >
      <form-panel
        ref="form"
        :form="skuObj"
        :hasHeader="false"
        :footerShow="false"
      >
        <el-form-item
          label="规格名称"
          :rules="[
            { required: true, message: '请输入规格名称', trigger: 'blur' },
          ]"
          prop="skuName"
        >
          <v-input :width="width" v-model="skuObj.skuName" :maxlength="20">
          </v-input>
        </el-form-item>
        <el-form-item
          label="单价"
          :rules="[
            { required: true, message: '请输入单价', trigger: 'blur' },
            {
              message: '必须是数字格式，且精度为小数点后两位',
              pattern: /^[0-9]\d*(.\d{1,2})?$/,
              trigger: 'blur',
            },
          ]"
          prop="price"
        >
          <v-input :width="width" v-model="skuObj.price"> </v-input>
        </el-form-item>
        <el-form-item
          label="规格图片"
          :rules="[
            { required: true, message: '请上传规格图片', trigger: 'blur' },
          ]"
          prop="skuImageUrl"
        >
          <v-upload
            :key="`${curIndex}_upload`"
            :limit="1"
            :imgUrls.sync="skuObj.skuImageUrl"
          />
        </el-form-item>
        <el-form-item
          label="库存"
          :rules="[
            { required: false, message: '请输入库存', trigger: 'blur' },
            {
              message: '必须是数字格式',
              pattern: /^[0-9]+$/,
              trigger: 'blur',
            },
          ]"
          prop="stock"
        >
          <v-input
            placeholder="默认为无限制"
            :width="width"
            v-model="skuObj.stock"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="排序"
          :rules="[
            { required: true, message: '请输入商品排序值', trigger: 'blur' },
            {
              message: '必须是数字格式',
              pattern: /^[0-9]+$/,
              trigger: 'blur',
            },
          ]"
          prop="sortIndex"
        >
          <v-input :width="width" v-model="skuObj.sortIndex"></v-input>
        </el-form-item>
      </form-panel>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  addOrUpdateGoodsInfoURL,
  getGoodsDetailsURL,
  getSpaceTreeListURL,
  getMallTypeIconListURL,
  getSubMchIdsURL,
} from "./api.js";
import {
  // shelfStsMap,
  setShelfStsOps,
} from "./map.js";
import { createImgVNode } from "@/utils/utils.js";

export default {
  data() {
    return {
      onOff: false,
      isView: false,
      width: 250,
      submitConfig: {
        queryUrl: getGoodsDetailsURL,
        submitUrl: addOrUpdateGoodsInfoURL,
      },
      shelfStsOps: setShelfStsOps(),
      goodsTypeOps: [],
      subMchInfoIdOps: [],
      curGoodsType: {},
      form: {
        id: "",
        authSpaceIds: "",
        goodsType: "",
        imageUrl: "",
        title: "",
        detailContent: "",
        price: "",
        stock: "",
        otherLink: "",
        shelfSts: 0,
        getAddress: [""],
        subMchInfoId: "",
        skuList: [],
      },
      /** v-cascader */
      authSpaceIds: [], // v-model 选中的集合
      spacePathIds: [], // 扁平化数据
      communityOps: [], // 树结构数据
      props: {
        multiple: true,
        label: "name",
        value: "id",
        children: "children",
      },
      /** v-dialog */
      headers: [
        {
          prop: "skuImageUrl",
          label: "图片",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "skuName",
          label: "规格名称",
        },
        {
          prop: "price",
          label: "单价",
        },
        {
          prop: "stock",
          label: "库存",
          formatter: (row, prop) => {
            if (row[prop] !== 0 && row[prop] == "") {
              return "无限量";
            }
            return `${row[prop]}`;
          },
        },
        {
          prop: "sortIndex",
          label: "排序",
        },
      ],
      curIndex: undefined,
      isDialog: false,
      skuObj: {
        id: "",
        goodsId: "",
        price: "",
        skuName: "",
        skuImageUrl: "",
        sortIndex: 0,
        stock: 0,
      },
    };
  },
  computed: {
    ...mapState("app", ["userInfo"]),
  },
  watch: {
    // 选中的区域集合
    authSpaceIds: {
      handler(val) {
        this.form.authSpaceIds = val.map((a) => a[a.length - 1]).join(",");
      },
      deep: true,
    },
    "form.goodsType": {
      handler(val) {
        const ops = this.goodsTypeOps.filter((v) => v.id == val);
        this.curGoodsType = ops.length > 0 ? ops[0] : [];
        if (this.onOff) {
          return;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    const { id, isView } = this.$route.query;
    this.form.id = id;
    this.isView = !!isView;
    await Promise.all([
      this.getCommunityOps(),
      this.getMallTypeIconList(),
      this.getSubMchIds(),
    ]);
    this.onOff = !!id;
    if (id !== undefined) {
      this.$refs.formPanel.getData({ id });
    }
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    // 获取商品icon图
    async getMallTypeIconList() {
      let res = await this.$axios.get(getMallTypeIconListURL);
      if (res.code == 200) {
        this.goodsTypeOps = res.data
          .filter((v) => !!v.isShow)
          .map((v) => ({
            value: v.id,
            label: v.typeName,
            ...v,
          }));
      }
    },
    // 获取获取可用的sub_mch_id
    async getSubMchIds() {
      let res = await this.$axios.get(getSubMchIdsURL, {
        params: {
          userId: this.userInfo.userId,
        },
      });
      if (res.code == 200) {
        this.subMchInfoIdOps = res.data.map((v) => ({
          value: v.id,
          label: v.shopName,
          ...v,
        }));
      }
    },
    async getCommunityOps() {
      let res = await this.$axios.get(
        `${getSpaceTreeListURL}?specifyCollectionTypes=0&topSpaceId=P1C2A2&excludeTopSpaceId=no`
      );
      if (res.code === 200) {
        let opt = this.dealDataAddParentIds(res.data);
        this.communityOps = opt;
      }
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        if (key === "authSpaceTypes" && !data[key]) {
          this.form[key] = "";
          return;
        }
        this.form[key] = data[key];
      });
      const ids = this.form.authSpaceIds
        ? this.form.authSpaceIds.split(",")
        : [];
      let authIds = [];
      this.spacePathIds.forEach((item) => {
        if (ids.includes(item.id)) {
          authIds.push([...item.treeIds]);
        }
      });
      this.authSpaceIds = authIds;
      this.$nextTick(() => {
        this.onOff = false;
      });
    },
    // 提货地点
    setAddress(item, index, arr) {
      // 点击第0个元素图片会新增地址，反之删除点击的地址
      if (index == 0) {
        arr.push("");
      } else {
        arr.splice(index, 1);
      }
    },
    submitBefore() {
      if (this.curGoodsType.category != 2) {
        if (!this.form.skuList || this.form.skuList.length == 0) {
          this.$message.error("请编辑商品的规格数据");
          return;
        }
      }
      return true;
    },
    submitSuccess() {
      return true;
    },

    /** 处理数据 */
    // 数据添加 parentIds
    dealDataAddParentIds(data, ids = []) {
      data.forEach((v) => {
        if (v.children) {
          this.dealDataAddParentIds(v.children, [...ids, v.id]);
        } else {
          this.spacePathIds.push(v);
        }
        v.treeIds = [...ids, v.id];
      });
      return data;
    },

    /** v-dialog */
    close() {
      this.isDialog = false;
    },
    addSku() {
      this.curIndex = undefined;
      this.skuObj = {
        // id: "",
        goodsId: "",
        price: "",
        skuName: "",
        skuImageUrl: "",
        sortIndex: 0,
        stock: "",
      };
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
      }
      this.isDialog = true;
    },
    editSku(index) {
      console.log(index, "index---");
      this.curIndex = index;
      let data = this.form.skuList[index];
      // this.skuObj = {
      //   skuName: data.skuName,
      //   price: data.price,
      //   skuImageUrl: data.skuImageUrl,
      //   sortIndex: data.sortIndex,
      //   stock: data.stock,
      // };
      let skuObj = {
        skuName: data.skuName,
        price: data.price,
        skuImageUrl: data.skuImageUrl,
        sortIndex: data.sortIndex,
        stock: data.stock,
      };
      if (data.id) {
        // this.skuObj.id = data.id;
        skuObj.id = data.id;
      }
      if (data.goodsId) {
        // this.skuObj.goodsId = data.goodsId;
        skuObj.goodsId = data.goodsId;
      }
      this.skuObj = skuObj;
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
      }
      this.isDialog = true;
    },
    delSku(index) {
      let data = this.form.skuList[index];
      this.$confirm("确定要删除" + data.skuName + "?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.form.skuList.splice(index, 1);
      });
    },
    saveSku() {
      const result = this.$refs.form.validate();
      if (!result) {
        return;
      }
      // this.curIndex 存在则是编辑，反之为新增
      if (this.curIndex !== undefined) {
        this.$set(this.form.skuList, this.curIndex, {
          ...this.form.skuList[this.curIndex],
          ...this.skuObj,
        });
      } else {
        this.form.skuList.push(this.skuObj);
      }
      this.isDialog = false;
    },
  },
};
</script>
<style lang="less" scoped>
.section {
  box-sizing: border-box;
  height: 100%;
  .check-panel {
    display: flex;
  }
  .address {
    display: flex;
    flex-direction: column;
    .address-input {
      &:not(:first-child) {
        margin-top: 10px;
      }
      display: flex;
      align-items: center;
      ::v-deep .v-button {
        margin-left: 20px;
      }
    }
  }
  .sku {
    display: flex;
    ::v-deep > .v-control {
      margin-right: 40px;
      width: 120px;
    }
    .sku-table {
      width: 1000px;
      height: 500px;
    }
  }
}
</style>
